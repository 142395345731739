import React from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';

const WrapperStyle = {
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	height: '100vh',
	paddingTop: '64px',
	paddingBottom: '64px',
};

const ContainerStyle = {
	width: '100%',
	marginLeft: 'auto',
	boxSizing: 'border-box',
	marginRight: 'auto',
	display: 'block',
	paddingLeft: '16px',
	paddingRight: '16px',

	'@media(min-width: 600px)': {
		paddingLeft: '24px',
		paddingRight: '24px',
	},
	'@media(min-width: 1200px)': {
		maxWidth: '1200px',
	},
};

const StackStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '48px',
};

const ImageStyle = {
	maxWidth: '100%',
	width: '200px',
	height: 'auto',
};

const ContentStackStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
	textAlign: 'center',
};

const HeadingStyle = {
	margin: 0,
	fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '2rem',
	lineHeight: 1.2,
};

const BodyStyle = {
	margin: 0,
	fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '1rem',
	lineHeight: 1.5,
	color: 'var(--mui-palette-text-secondary)',
};

export const NotFound: React.FunctionComponent = () => (
	<Box sx={WrapperStyle} component={'main'}>
		<Container sx={ContainerStyle}>
			<Stack sx={StackStyle}>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box sx={ImageStyle} component={'img'} alt={'Not Found'} src={'/assets/not-found.svg'} />
				</Box>
				<Stack sx={ContentStackStyle}>
					<Typography sx={HeadingStyle} variant={'h4'}>404: Not Found</Typography>
					<Typography sx={BodyStyle} variant={'body1'}>The page you are looking for does not exist.</Typography>
				</Stack>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button variant={'contained'} onClick={() => window.history.back()}>Go Back</Button>
				</Box>
			</Stack>
		</Container>
	</Box>
);

export default NotFound;
