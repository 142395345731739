import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface IProps {
	title?: string;
	children?: React.ReactNode;
}

const WrapperStyle = {
	'--Content-margin': '0 auto',
	'--Content-maxWidth': 'var(--maxWidth-xl)',
	'--Content-paddingX': '24px',
	'--Content-padding': 'var(--Content-paddingY) var(--Content-paddingX)',
	'--Content-width': '100%',

	display: 'flex auto',
	flex: '1 1 auto',
	flexDirection: 'column auto',

	'@media(min-width: 1200px)': {
		'--Content-paddingY': '64px',
	},
};

const WrapperInnerStyle = {
	maxWidth: 'var(--Content-maxWidth)',
	margin: 'var(--Content-margin)',
	padding: 'var(--Content-padding)',
	width: 'var(--Content-width)',
};

const WrapperStackStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '32px',
};

const WrapperTitleStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
};

const WrapperTitleTextStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: '500',
	fontSize: '2rem',
	lineHeight: '1.2',
};

export const DashboardContent: React.FunctionComponent<IProps> = (props) => (
	<Box sx={WrapperStyle}>
		<Box sx={WrapperInnerStyle}>
			<Stack sx={WrapperStackStyle}>
				<Stack sx={WrapperTitleStyle}>
					<Box sx={{ flex: '1 1 auto' }}>
						<Typography sx={WrapperTitleTextStyle} variant={'h4'}>{props.title}</Typography>
					</Box>
				</Stack>
				{props.children}
			</Stack>
		</Box>
	</Box>
);

export default DashboardContent;
