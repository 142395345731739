import React, { useState } from 'react';
import { Box, Stack, Card, CardHeader, CardContent, Grid, Avatar, Button, TextField, Alert } from '@mui/material';
import { AlternateEmail } from '@mui/icons-material';
import Logo from 'src/components/Logo';
import Turnstile from 'src/components/Turnstile';
import { LoginPath } from 'src/pages/auth/login';
import AnywaysAPI from 'src/services/anyways';

const PageContainer = {
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	minHeight: '100vh',

	'@media(min-width: 900px)': {
		padding: '24px'
	}
};

const LoginContainer = {
	maxWidth: '560px',
	width: '100%',
};

const LoginStack = {
	display: 'flex',
	flexDirection: 'column',
	gap: '32px',
};

const CardStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '20px',
	maxWidth: '800px',

	'&.MuiPaper-elevation1': {
		boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
	}
};

const IconStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	boxShadow: 'var(--mui-shadows-8)',
	color: 'var(--mui-palette-text-primary)',
};

const CardTitleStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '1.125rem',
	lineHeight: 1.2,
	display: 'block',
};

const CardContentStyle = {
	padding: '16px 24px 32px',
	paddingBottom: '32px',

	'&:last-child': {
		paddingBottom: '32px',
	}
};

const StackStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '16px',
};

async function doRecover(e: React.FormEvent<HTMLFormElement>) {
	e.preventDefault();
	const form = e.target as HTMLFormElement;
	const formData = new FormData(form);
	
	try {
		const res = await fetch(`${AnywaysAPI.BaseURL()}/v1/auth/login/recover`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: [...formData.entries()].map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1] as any)).join("&"),
		});
		
		if(res.ok) {
			console.log('Login successful');
		} else {
			console.error('Login failed');
		}
	} catch(error) {
		console.error('Error during login:', error);
	}
}

const RecoveryForm: React.FunctionComponent<{ disabled?: boolean }> = (props) => {
	const [error, setError] = useState<string | null>(null);
	return (
		<Stack sx={StackStyle} component={'form'} onSubmit={doRecover}>
			{error && <Alert severity={'error'}>{error}</Alert>}
			<TextField label={'Email'} name={'email'} required />
			<Box style={{ marginLeft: 'auto', marginRight: 0 }}><Turnstile action={'recovery'} onValidateFailure={(msg => setError(msg || null))} /></Box>
			<Box sx={{ display: 'flex', justifyContent: 'end' }}>
				<Button sx={{ marginRight: '10px' }} variant={'text'} color={'secondary'} href={LoginPath}>Remember Password?</Button>
				<Button variant={'contained'} type={'submit'}>Send Email</Button>
			</Box>
		</Stack>
	);
};

export const RecoveryPath = '/auth/recover';
export const Recovery: React.FunctionComponent = () => (
	<Box sx={PageContainer}>
		<Box sx={LoginContainer}>
			<Stack sx={LoginStack}>
				<Logo sx={{ fontSize: '32px' }} variant={'h6'} />
				<Grid container spacing={2} sx={{ pointerEvents: 'none' }} justifyContent={'center'} alignItems={'center'}>
					<Grid item xs={12} sx={{ minWidth: '450px', pointerEvents: 'auto' }}>
						<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
							<Card sx={CardStyle} elevation={1}>
								<CardHeader sx={{ display: 'flex', alignItems: 'center', padding: '32px 24px 16px' }}
									avatar={<Avatar sx={IconStyle} variant={'circular'}><AlternateEmail /></Avatar>}
									titleTypographyProps={{ sx: CardTitleStyle }} title={'Reset Password'} />
								<CardContent sx={CardContentStyle}>
									<Stack sx={StackStyle}>
										<RecoveryForm />
									</Stack>
								</CardContent>
							</Card>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</Box>
	</Box>
);
export default Recovery;
