import React from 'react';
import { Box, Stack, Typography, Link } from '@mui/material';

interface INavigationProps {
	children: React.ReactNode;
}

interface INavGroupProps {
	title: string;
	children: React.ReactNode;
}

interface INavItemProps {
	to: string;
	title: string;
	icon: React.ReactNode;
	active?: boolean;
}

interface INavigationContext {
	url: string;
	setUrl: (url: string) => void;
}

const NavigationContext = React.createContext<INavigationContext>({ url: window.location.pathname, setUrl: () => {} });

const NavStyle = {
	flex: '1 1 auto',
	overflowY: 'auto',
	padding: '16px',
	scrollbarWidth: 'none',
	color: 'var(--mui-palette-text-primary)',

	'a': {
		textDecoration: 'none',
		color: 'var(--mui-palette-neutral-500)',

		'&:hover:not([data-active="true"])': {
			backgroundColor: 'var(--mui-palette-action-hover)',
			color: 'var(--mui-palette-text-primary)',
		},
	},
};

const NavGroupStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '16px',
	listStyle: 'none',
	padding: 0,
	margin: 0,
};

const NavTitleStyle = {
	margin: '0px',
	color: 'var(--mui-palette-nav-title)',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	lineHeight: 1.5,
	fontSize: '0.875rem',
	fontWeight: 500,
	userSelect: 'none',
};

const NavListStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
	listStyle: 'none',
	margin: '0px',
	padding: '0px',

	'a': {
		alignItems: 'center',
		borderRadius: '8px',
		cursor: 'pointer',
		display: 'flex',
		flex: '0 0 auto',
		gap: '8px',
		padding: '6px 16px',
		position: 'relative',
		textDecoration: 'none',
		whiteSpace: 'nowrap',
	},

	'a[data-active="true"]': {
		alignItems: 'center',
		borderRadius: '8px',
		color: '#ffffff',
		cursor: 'pointer',
		display: 'flex',
		flex: '0 0 auto',
		gap: '8px',
		padding: '6px 16px',
		position: 'relative',
		textDecoration: 'none',
		whiteSpace: 'nowrap',
		backgroundColor: '#635bff',
	},
};

export const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
	const [url, setUrl] = React.useState(window.location.pathname);
	return (
		<NavigationContext.Provider value={{ url, setUrl: (url) => setUrl(url) }}>
			<Box sx={NavStyle} component={'nav'}>
				<Stack sx={NavGroupStyle} component={'ul'}>{props.children}</Stack>
			</Box>
		</NavigationContext.Provider>
	)
};

export const NavGroup: React.FunctionComponent<INavGroupProps> = (props) => (
	<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }} component={'li'}>
		<div>
			<Typography sx={NavTitleStyle} variant={'body1'}>{props.title}</Typography>
		</div>
		<div>
			<Stack sx={NavListStyle} component={'ul'}>
				{props.children}
			</Stack>	
		</div>
	</Stack>
);

export const NavItem: React.FunctionComponent<INavItemProps> = (props) => {
	const context = React.useContext(NavigationContext);
	return (
		<Stack sx={{ userSelect: 'none' }} component={'li'}>
			<Link href={props.to} data-active={context.url === props.to? 'true': undefined} onClick={() => context.setUrl(props.to)}>
				<Box sx={{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'center',
					flex: '0 0 auto',
				}}>{props.icon}</Box>
				<Box>
					<Typography sx={{
						margin: '0px',
						fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
						color: 'inherit',
						fontSize: '0.875rem',
						fontWeight: 500,
						lineHeight: '28px',
					}} variant={'body1'}>{props.title}</Typography>
				</Box>
			</Link>
		</Stack>
	)
};

export default Navigation;
