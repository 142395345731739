import React, { Suspense, lazy, ComponentType } from 'react';
import { CircularProgress, Container } from '@mui/material';

interface AsyncLoaderProps {
	loader: () => Promise<{ default: ComponentType<any> }>;
	fallback?: React.ReactNode;
	delay?: number;
}

const wait = (promise: Promise<{ default: ComponentType<any> }>, ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms)).then(() => promise);
}

const LoadingPageStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100vh',
};

export const LoadingPage: React.FunctionComponent = () => (
	<Container sx={LoadingPageStyle}>
		<CircularProgress size={60} />
	</Container>
);

export const AsyncLoader: React.FunctionComponent<AsyncLoaderProps> = ({ loader, fallback = <React.Fragment />, delay }) => {
	const LazyComponent = lazy(delay ? () => wait(loader(), delay) : loader);

	return (
		<Suspense fallback={fallback}>
			<LazyComponent />
		</Suspense>
	);
};

export default AsyncLoader;
