import { Box } from '@mui/material';
import React from 'react';

interface IProps {
	children?: React.ReactNode;
}

const LayoutStyle = {
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
	minHeight: '100%'
};

export const DashboardPage: React.FunctionComponent<IProps> = (props) => (
	<Box sx={LayoutStyle}>{props.children}</Box>
);

export default DashboardPage;
