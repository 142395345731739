import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Avatar, Box, Button, Card, CardActions, FormControlLabel, FormGroup, Radio, Stack, Typography, useColorScheme } from '@mui/material';
import { FormatColorFill, LightModeOutlined, DarkModeOutlined, Computer, WarningAmber, DeleteOutline } from '@mui/icons-material';
import Dashboard from 'src/components/Dashboard';
import SettingsPanel, { SettingsTitle } from 'src/components/SettingsPanel';
import Modal from 'src/components/Modal';
import { SettingsNavigation } from 'src/pages/settings';
import { LoginPath } from 'src/pages/auth/login';
import AnywaysAPI from 'src/services/anyways';
import store from 'src/store';

interface IThemeLabel {
	icon: React.ReactNode;
	title: string;
	description: string;
}

interface IThemeOptionProps {
	icon: React.ReactNode;
	title: string;
	description: string;
	currentMode?: 'light' | 'dark' | 'system';
	targetMode: 'light' | 'dark' | 'system';
	setMode: (mode: 'light' | 'dark' | 'system' | null) => void;
}

const PageWrapperStyle = {
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'column',

	'--Content-margin': '0 auto',
	'--Content-maxWidth': '1440px',
	'--Content-paddingX': '24px',
	'--Content-padding': 'var(--Content-paddingY) var(--Content-paddingX)',
	'--Content-width': '100%',

	'@media (min-width: 0px)': {
		'--Content-paddingY': '24px',
	},
	'@media (min-width: 1200px)': {
		'--Content-paddingY': '64px',
	},
};

const PageContentStyle = {
	maxWidth: 'var(--Content-maxWidth)',
	margin: 'var(--Content-margin)',
	padding: 'var(--Content-padding)',
	width: 'var(--Content-width)',
};

const SettingsWrapperStyle = {
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',

	'@media (min-width: 0px)': {
		flexDirection: 'column',
		gap: '32px',
	},

	'@media (min-width: 900px)': {
		flexDirection: 'row',
		gap: '32px',
	},
};

const ThemeSettingOptionContainerStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	border: '1px solid var(--mui-palette-divider)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '20px',
};

const ThemeSettingOptionStyle = {
	display: 'inline-flex',
	alignItems: 'center',
	cursor: 'pointer',
	verticalAlign: 'middle',
	flexDirection: 'row-reverse',
	gap: '8px',
	margin: '0px',

	'&.MuiFormControlLabel-root': {
		justifyContent: 'space-between',
		padding: '8px 12px',

		'&:not(:last-of-type)': {
			borderBottom: '1px solid var(--mui-palette-divider)',
		}
	}
};

const DangerZoneDescriptionStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '1rem',
	lineHeight: '1.57px'
};

const ThemeLabel: React.FunctionComponent<IThemeLabel> = (props) => (
	<Stack sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
		<Avatar sx={{ color: 'var(--mui-palette-background-default)' }} variant={'circular'}>{props.icon}</Avatar>
		<div>
			<Typography sx={{ margin: '0px', font: 'inherit' }} variant={'inherit'}>{props.title}</Typography>
			<Typography sx={{
				margin: '0px',
				fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
				fontWeight: 400,
				fontSize: '0.75rem',
				lineHeight: 1.66,
				color: 'var(--mui-palette-text-secondary)',
			}} variant={'caption'}>{props.description}</Typography>
		</div>
	</Stack>
);

const ThemeOption: React.FunctionComponent<IThemeOptionProps> = (props) => (
	<FormControlLabel sx={ThemeSettingOptionStyle}
		label={<ThemeLabel icon={props.icon} title={props.title} description={props.description} />}
		control={<Radio />}
		labelPlacement={'start'}
		checked={props.currentMode === props.targetMode}
		onClick={() => props.setMode(props.targetMode)} />
);

const ThemeSelectPanel: React.FunctionComponent = () => {
	const { mode, setMode } = useColorScheme();
	return (
		<SettingsPanel icon={<FormatColorFill />} title={'Theme'}>
			<Card variant={'outlined'} sx={ThemeSettingOptionContainerStyle}>
				<FormGroup sx={{ display: 'flex', flexFlow: 'column wrap', gap: '0px' }} role={'radiogroup'}>
					<ThemeOption title={'Light Mode'} description={'Best for bright environments'} icon={<LightModeOutlined />} targetMode={'light'} currentMode={mode} setMode={setMode} />
					<ThemeOption title={'Dark Mode'} description={'Recommended for dark rooms'} icon={<DarkModeOutlined />} targetMode={'dark'} currentMode={mode} setMode={setMode} />
					<ThemeOption title={'System'} description={'Adapts to your device\'s theme'} icon={<Computer />} targetMode={'system'} currentMode={mode} setMode={setMode} />
				</FormGroup>
			</Card>
		</SettingsPanel>
	);
};

const DeactivateAction = observer(() => {
	const [ loading, setLoading ] = useState(false);
	const doDeactivate = () => {
		setLoading(true);
		AnywaysAPI.DeactivateAccount().then(() => {
			store.auth.Logout();
			setLoading(false);
		}).catch((err) => {
			console.error('Failed to deactivate account', err);
			setLoading(false);
		});
	};

	if(!store.IsLoggedIn) return <Navigate to={LoginPath} />;
	return <Button variant={'outlined'} color={'error'} onClick={loading ? undefined : doDeactivate} disabled={loading}>{loading ? 'Deactivating' : 'Deactivate'}</Button>
});

const DangerPanel: React.FunctionComponent = () => {
	const [isDeactivateOpen, setDeactivateOpen] = useState(false);
	return (
		<SettingsPanel icon={<WarningAmber sx={{ fill: 'var(--mui-palette-error-main)' }} />} titleSx={{ color: 'var(--mui-palette-error-main)' }} title={'Danger Zone'}>
			<Typography variant={'subtitle1'} sx={DangerZoneDescriptionStyle}>Actions in this section are irreversible and cannot be undone.</Typography>
			<CardActions sx={{ padding: '25px 0 0 0' }}>
				<Button variant={'outlined'} color={'error'} onClick={() => setDeactivateOpen(true)}>Deactivate Account</Button>
			</CardActions>
			<Modal icon={<DeleteOutline sx={{ fill: 'var(--mui-palette-error-main)' }} />} titleSx={{ color: 'var(--mui-palette-error-main)' }} title={'Deactivate Account'} open={isDeactivateOpen} onClose={() => setDeactivateOpen(false)}>
				<Typography>
					Once deactivated, you will have 30 days to log in again to cancel permanent deletion of all account data.
					Once your account has been deleted it can not be recovered.
				</Typography>
				<CardActions sx={{ padding: '25px 0 0 0', justifyContent: 'right' }}>
					<DeactivateAction />
					<Button variant={'contained'} color={'primary'} onClick={() => setDeactivateOpen(false)}>Cancel</Button>
				</CardActions>
			</Modal>
		</SettingsPanel>
	)
};

export const AccountSettingsPath = '/dashboard/settings/account';
export const AccountSettings: React.FunctionComponent = () => (
	<Dashboard>
		<Box sx={PageWrapperStyle} component={'main'}>
			<Box sx={PageContentStyle}>
				<Stack sx={SettingsWrapperStyle}>
					<SettingsNavigation />
					<Box sx={{ flex: '1 1 auto', minWidth: '0px' }}>
						<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
							<SettingsTitle title={'Account'} />
							<ThemeSelectPanel />
							<DangerPanel />
						</Stack>
					</Box>
				</Stack>
			</Box>
		</Box>
	</Dashboard>
);

export default AccountSettings;
