import React from 'react';
import Dashboard, { DashboardContent } from 'src/components/Dashboard';

export const OverviewPath = '/dashboard/overview';
export const Overview: React.FunctionComponent = () => (
	<Dashboard>
		<DashboardContent title={'Overview'} />
	</Dashboard>
);

export default Overview;
