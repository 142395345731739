import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Login as LoginIcon } from '@mui/icons-material';
import { SxProps, Theme, Stepper, Step, Box, Stack, Card, CardHeader, CardContent, Avatar, Button, Divider, Typography, TextField, Alert, Link } from '@mui/material';
import Turnstile from 'src/components/Turnstile';
import Logo from 'src/components/Logo';
import AnywaysAPI from 'src/services/anyways';
import { OverviewPath } from '../dashboard/overview';
import { RecoveryPath } from './recovery';

interface IOAuthProps {
	name: string;
	logo: string;
	url?: string;
}

interface ILoginStep {
	next: () => void;
	previous: () => void;
	setAccount: (email: string, exists: boolean) => void;
	clearAccount: () => void;
	setAlert: (severity: AlertSeverity, message: string) => void;
	clearAlert: () => void;
	data: ILoginData;
}

type AlertSeverity = 'error' | 'warning' | 'info' | 'success';

interface ILoginData {
	alert?: IAlert | null;
	account?: IAccount | null;
}

interface IAlert {
	severity: AlertSeverity;
	message: string;
}

interface IAccount {
	exists: boolean;
	email: string;
}

const providers: IOAuthProps[] = [
	{ name: 'Twitch', url: `${AnywaysAPI.BaseURL()}/auth/provider/twitch`, logo: '/assets/twitch-glitch.png' },
]

const loginSteps: React.FunctionComponent<ILoginStep>[] = [
	({ next, setAccount, setAlert }) => {
		const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			try {
				const form = e.target as HTMLFormElement;
				const formData = new FormData(form);
				const res = await fetch(`${AnywaysAPI.BaseURL()}/auth/lookup`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: [...formData.entries()].map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1] as any)).join("&"),
				});

				switch(res.status) {
					case 404:
					case 302:
						setAccount(formData.get('email') as string, res.status < 400);
						next();
						return;
					default:
						setAlert('error', await res.text());
				}
			} catch(error) {
				setAlert('error', 'An error occurred.');
			}
		}

		return (
			<>
				<Stack sx={{ justifyContent: 'space-evenly' }} direction={'column'} spacing={2}>
					{ providers.map((provider) => <OAuthButton key={provider.name} {...provider} />) }
				</Stack>
				<AuthenticationDivider />
				<Stack sx={StackStyle} component={'form'} onSubmit={onSubmit}>
					<TextField label={'Email'} name={'email'} required />
					<Box style={{ marginLeft: 'auto', marginRight: 0 }}>
						<Turnstile action={'lookup'} onValidateFailure={(msg) => msg ? setAlert('error', msg) : 0} />
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'end' }}>
						<Button variant={'contained'} type={'submit'}>Continue with Email</Button>
					</Box>
				</Stack>
			</>
		)
	},
	({ next, previous, setAlert, clearAlert, data }) => {
		const onLogin = async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			try {
				const form = e.target as HTMLFormElement;
				const formData = new FormData(form);
				const res = await fetch(`${AnywaysAPI.BaseURL()}/auth/login`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: [...formData.entries()].map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1] as any)).join("&"),
					credentials: 'include',
				});

				if(res.status < 400) {
					setAlert('success', 'Logged in successfully!');
					next();
					window.location.reload();
					return;
				}
				setAlert('error', await res.text());
			} catch(error) {
				setAlert('error', 'An unknown error occurred');
			}
		}

		const onRegister = async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			
			try {
				const formData = new FormData(e.target as HTMLFormElement);
				const res = await fetch(`${AnywaysAPI.BaseURL()}/auth/register`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: [...formData.entries()].map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1] as any)).join("&"),
				});
				
				if(res.status < 400) {
					setAlert('success', 'Account created successfully!');
					previous();
					return;
				}
				setAlert('error', await res.text());
			} catch(err) {
				setAlert('error', 'An unknown error occurred');
			}
		}

		const cancel = () => {
			clearAlert();
			previous();
		}

		return (
			<Stack sx={StackStyle} component={'form'} onSubmit={data.account?.exists ? onLogin : onRegister}>
				{ !data.account?.exists ? <TextField label={'Username'} name={'username'} required /> : <></> }
				<TextField label={'Email'} name={'email'} type={'email'} value={data.account?.email} required />
				<TextField label={'Password'} name={'password'} type={'password'} required />
				{ !data.account?.exists ? <TextField label={'Confirm Password'} name={'confirm-password'} type={'password'} required /> : <></> }
				{ data.account?.exists ? <Link sx={RecoveryLinkStyle} href={RecoveryPath}>Forgot password?</Link> : <></> }
				<Box style={{ marginLeft: 'auto', marginRight: 0 }}>
					<Turnstile action={data.account?.exists ? 'login' : 'register'} onValidateFailure={(msg) => msg ? setAlert('error', msg) : 0} />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button variant={'outlined'} color={'secondary'} onClick={cancel}>Back</Button>
					<Button variant={'contained'} type={'submit'} disabled={!data.account?.exists}>{ data.account?.exists ? 'Login' : 'Sign Up' }</Button>
				</Box>
			</Stack>
		)
	},
	({ data }) => {
		if(data.alert?.severity === 'success')
			return data.account?.exists ? <Navigate to={OverviewPath} /> : <></>
		return <Navigate to={LoginPath} />
	}
]

const PageContainer = {
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	minHeight: '100vh',
	padding: '24px',
}

const LoginContainer = {
	maxWidth: '400px',
	width: '100%',
}

const CardStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '20px',
	maxWidth: '800px',
	width: '100%',
	'&.MuiPaper-elevation1': {
		boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
	}
}

const IconStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	boxShadow: 'var(--mui-shadows-8)',
	color: 'var(--mui-palette-text-primary)',
}

const CardTitleStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '1.125rem',
	lineHeight: 1.2,
	display: 'block',
}

const CardContentStyle = {
	padding: '16px 24px 32px',
	paddingBottom: '32px',
	'&:last-child': {
		paddingBottom: '32px',
	}
}

const DividerStyle = {
	display: 'flex',
	alignItems: 'center',
	margin: '16px 8px 16px 8px',

	'&::before, &::after': {
		content: '""',
		flex: 1,
		borderBottom: '1px solid var(--mui-palette-divider)',
	},

	'& .MuiTypography-root': {
		padding: '0 10px',
	},
}

const StackStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '16px',
}

const StepStyle = {
	width: '100%',
	paddingLeft: 0,
	paddingRight: 0,
}

const HiddenStepStyle = {
	display: 'none',
}

const RecoveryLinkStyle = {
	textDecoration: 'none',
	color: 'var(--mui-palette-text-secondary)',
	'&:hover': {
		color: 'var(--mui-palette-text-primary)',
	}
}

const OAuthLogo: React.FunctionComponent<IOAuthProps> = (props) => (
	<Avatar
		sx={{ width: 24, height: 24, cursor: props.url ? 'pointer' : 'not-allowed' }}
		slotProps={{ img: { sx: { objectFit: 'contain', filter: props.url ? 'none' : 'grayscale(1)' }, draggable: false } }}
		variant={'square'}
		src={props.logo} />
);

const OAuthButton: React.FunctionComponent<IOAuthProps> = (props) => (
	<Button variant={'outlined'} href={props.url} startIcon={<OAuthLogo {...props} />} fullWidth>Continue with {props.name}</Button>
);

const AuthenticationDivider: React.FunctionComponent<{ sx?: SxProps<Theme> }> = (props) => (
	<Divider sx={DividerStyle}>
		<Typography variant={'body2'} color={'text.secondary'}>or</Typography>
	</Divider>
);

export const LoginPath = '/auth/login';
export const Login: React.FunctionComponent = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [data, setData] = useState<ILoginData>({});
	const updateData = (value: ILoginData) => setData(Object.assign({}, data, value));
	return (
		<Box sx={PageContainer}>
			<Box sx={LoginContainer}>
				<Stack spacing={3}>
					<Logo sx={{ fontSize: '32px' }} variant={'h6'} />
					<Card sx={CardStyle} elevation={1}>
						<CardHeader
							sx={{ display: 'flex', alignItems: 'center', padding: '32px 24px 16px' }}
							avatar={<Avatar sx={IconStyle} variant={'circular'}><LoginIcon /></Avatar>}
							titleTypographyProps={{ sx: CardTitleStyle }}
							title={'Login'} />
						<CardContent sx={CardContentStyle}>
							{ data.alert ? <Alert sx={{ marginBottom: '16px' }} severity={data.alert.severity}>{data.alert.message}</Alert> : <></> }
							<Stepper activeStep={activeStep} connector={<></>}>
								{loginSteps.map((step, index) => (
									<Step key={index} sx={index === activeStep ? StepStyle : HiddenStepStyle}>
										{step({
											next: () => setActiveStep(activeStep + 1),
											previous: () => setActiveStep(activeStep - 1),
											setAccount: (email: string, exists: boolean) => updateData({ account: { email, exists } }),
											clearAccount: () => updateData({ account: null }),
											setAlert: (severity: AlertSeverity, message: string) => updateData({ alert: { severity, message } }),
											clearAlert: () => updateData({ alert: null }),
											data,
										})}
									</Step>
								))}
							</Stepper>
						</CardContent>
					</Card>
				</Stack>
			</Box>
		</Box>
	)
}
