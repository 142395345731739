import React from 'react';
import { observer } from 'mobx-react';
import { Avatar, Badge, Box, Card, Chip, Divider, Stack, Typography } from '@mui/material';
import { ShieldOutlined, UnfoldMore } from '@mui/icons-material';
import Modal from 'src/components/Modal';
import store from 'src/store';
import type { IAccountAccess } from 'src/store/auth';

interface IManageableProps {
	name: string;
	role: string;
	avatarUrl?: string;
	selected?: boolean;
	live?: boolean;
	onClick?: () => void;
}

const ContainerStyle = {
	display: 'flex',
	flexDirection: 'row',
	gap: '16px',
	alignItems: 'center',
	border: '1px solid var(--mui-palette-divider)',
	borderRadius: '12px',
	cursor: 'pointer',
	padding: '4px 8px',
	userSelect: 'none',

	'&:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
	}
};

const LiveIndicatorStyle = {
	width: 10,
	height: 10,
	borderRadius: '50%',
	backgroundColor: 'red',
	animation: 'pulse 1.5s infinite',
	'@keyframes pulse': {
		'0%': {
			transform: 'scale(0.95)',
			boxShadow: '0 0 0 0 rgba(255, 0, 0, 0.7)',
		},
		'70%': {
			transform: 'scale(1)',
			boxShadow: '0 0 0 5px rgba(255, 0, 0, 0)',
		},
		'100%': {
			transform: 'scale(0.95)',
			boxShadow: '0 0 0 0 rgba(255, 0, 0, 0)',
		},
	},
};

const RoleCaptionStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '0.75rem',
	lineHeight: 1.66,
	color: 'var(--mui-palette-neutral-400)',
};

const AccountTitleStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '0.875rem',
	lineHeight: 1.57,
};

const ManageCardStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	border: '1px solid var(--mui-palette-divider)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '8px',
};

const ManageChannelStyle = {
	display: 'flex',
	flexDirection: 'row',
	gap: '16px',
	alignItems: 'center',
	padding: '8px 16px',
	cursor: 'pointer',
	minWidth: '400px',

	'&[data-selected="true"]': {
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
	},

	'&:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
	},
};

const ManageChannelAvatarStyle = {
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexShrink: 0,
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	lineHeight: 1,
	overflow: 'hidden',
	userSelect: 'none',
	fontSize: '0.875rem',
	fontWeight: 500,
	height: '40px',
	width: '40px',
	letterSpacing: '0px',
	backgroundColor: 'var(--mui-palette-background-paper)',
	boxShadow: 'var(--mui-shadows-8)',
	color: 'var(--mui-palette-text-primary)',
};

const ManageChannelDisplayNameStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '0.875rem',
	lineHeight: 1.57,
};

const ManageChannelRoleStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '0.75rem',
	lineHeight: 1.66,
	color: 'var(--mui-palette-text-secondary)',
};

const ManageDivider = {
	margin: '0px',
	flexShrink: 0,
	borderWidth: '0px 0px thin',
	borderStyle: 'solid',
	borderColor: 'var(--mui-palette-divider)',
};

const ManageChannel: React.FunctionComponent<IManageableProps> = (props) => (
	<Stack sx={ManageChannelStyle} data-selected={props.selected ? 'true' : undefined} onClick={props.onClick}>
		{
			props.live ? (
				<Badge overlap={'circular'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<Box sx={LiveIndicatorStyle}/>}>
					<Avatar sx={ManageChannelAvatarStyle} variant={'circular'} alt={props.name} src={props.avatarUrl} />
				</Badge>
			) : <Avatar sx={ManageChannelAvatarStyle} variant={'circular'} alt={props.name} src={props.avatarUrl} />
		}
		<Box sx={{ flex: '1 1 auto' }}>
			<Typography sx={ManageChannelRoleStyle} variant={'caption'}>{props.role}</Typography>
			<Typography sx={ManageChannelDisplayNameStyle} variant={'subtitle2'}>{props.name}</Typography>
		</Box>
		{props.selected ? <Chip sx={{ color: 'var(--mui-palette-success-main)', borderColor: 'var(--mui-palette-success-main)' }} label={'Current'} variant={'outlined'} /> : <></>}
	</Stack>
);

export const ChannelSelect: React.FunctionComponent = observer(() => {
	const [open, setOpen] = React.useState(false);
	const setContextID = (id: string) => {
		store.auth.SetContextID(id).then(() => setOpen(false));
	};
	
	const channels = store.auth.access;
	const allowSelect = channels.length > 1;
	const selectedChannel: IAccountAccess | undefined = channels.find((channel) => channel._id === store.auth.context?._id);
	return (
		<>
			<Stack sx={ContainerStyle} onClick={allowSelect ? () => setOpen(true) : undefined}>
				{
					selectedChannel?.live ? (
						<Badge overlap={'rectangular'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<Box sx={LiveIndicatorStyle}/>}>
							<Avatar variant={'rounded'} src={selectedChannel?.avatarUrl} />
						</Badge>
					) : <Avatar variant={'rounded'} src={selectedChannel?.avatarUrl} />
				}
				<Box sx={{ flex: '1 1 auto' }}>
					<Typography sx={RoleCaptionStyle} variant={'caption'}>{selectedChannel?._id === store.auth.account?._id ? 'Owner' : 'Manager'}</Typography>
					<Typography sx={AccountTitleStyle} variant={'subtitle2'}>{selectedChannel?.displayName}</Typography>
				</Box>
				{ allowSelect ? <UnfoldMore sx={{ color: 'var(--mui-palette-neutral-300)' }} fontSize={'small'} /> : <></> }
			</Stack>
			{
				allowSelect ? (
					<Modal icon={<ShieldOutlined />} title={'Manage Channel'} open={open} onClose={() => setOpen(false)}>
						<Card sx={ManageCardStyle} variant={'outlined'}>
							<Stack sx={{ display: 'flex', flexDirection: 'column' }}>
								{
									channels.map((channel, i) => (
										<React.Fragment key={channel._id}>
											<ManageChannel
												name={channel.displayName}
												role={channel._id === store.auth.account?._id ? 'Owner' : 'Manager'}
												avatarUrl={channel.avatarUrl}
												selected={channel._id === selectedChannel?._id}
												onClick={() => setContextID(channel._id)}
												live={channel.live} />
											{channels.length - 1 > i ? <Divider sx={ManageDivider} /> : <></> }
										</React.Fragment>
									))
								}
							</Stack>
						</Card>
					</Modal>
				) : <></>
			}
		</>
	)
});

export default ChannelSelect;
