import { makeAutoObservable } from 'mobx';
import AuthStore from './auth';

export class RootStore {
	public auth: AuthStore;

	public constructor() {
		this.auth = new AuthStore();
		makeAutoObservable(this);
	}

	public get isReady(): boolean {
		return this.auth.isReady;
	}

	public get IsLoggedIn(): boolean {
		return this.auth.isAuthenticated;
	}
}

const store = new RootStore();
export default store;
