import React from 'react';
import TurnstileWidget, { useTurnstile } from 'react-turnstile';
import { useColorScheme } from '@mui/material';
import AnywaysAPI from 'src/services/anyways';

interface IProps {
	onValidateFailure?: (msg?: string) => void;
	appearance?: 'always' | 'execute'| 'interaction-only';
	size?: 'compact' | 'normal' | 'invisible';
	action?: string;
	cData?: string;
}

export const Turnstile: React.FunctionComponent<IProps> = (props) => {
	const turnstile = useTurnstile();
	const { colorScheme } = useColorScheme();
	return (
		<TurnstileWidget
			appearance={props.appearance || 'interaction-only'}
			action={props.action}
			cData={props.cData}
			size={props.size}
			theme={colorScheme}
			sitekey={'0x4AAAAAAAeO4Pt4N_RY18ew'}
			onExpire={() => turnstile.reset()}
			onVerify={(token: string) => {
				AnywaysAPI.Verify(token).then(async (res) => {
					if(!res.success) turnstile.reset();
				}).catch((err) => {
					turnstile.reset();
					props.onValidateFailure?.('Failed to validate frontend integrety.');
				});
			}} />
	);
}

export default Turnstile;
