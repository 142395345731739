import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import AnywaysAPI from 'src/services/anyways';
import { OverviewPath } from 'src/pages/dashboard/overview';
import { LoginPath } from 'src/pages/auth/login';
import { LoadingPage } from 'src/components/AsyncLoader';
import store from 'src/store';

export const LogoutPath = '/auth/logout';
export const Logout: React.FunctionComponent = observer(() => {
	const [redirect, setRedirect] = useState("");

	useEffect(() => {
		fetch(`${AnywaysAPI.BaseURL()}/auth/logout`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${store.auth.token}`
			},
		}).then((res) => {
			store.auth.Logout();
			setRedirect(LoginPath);
		}).catch((e) => {
			console.error('Something went wrong when logging out', e);
			setRedirect(OverviewPath);
		});
	}, []);

	return redirect ? <Navigate to={redirect} /> : <LoadingPage />
});
export default Logout;
