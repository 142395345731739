import React from 'react';
import Dashboard, { DashboardContent } from 'src/components/Dashboard';

export const AdminPath = '/dashboard/admin';
export const Admin: React.FunctionComponent = () => (
	<Dashboard>
		<DashboardContent title={'Admin'} />
	</Dashboard>
);

export default Admin;
