import React from 'react';
import { Box, Paper, Typography, IconButton, Stack, SxProps, Theme } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface IProps {
	title: string;
	titleStyle?: SxProps<Theme>;
	sx?: SxProps<Theme>;
	children?: React.ReactNode;
}

const ApplicationStyle: SxProps<Theme> = {
	width: 800,
	height: 600,
	borderRadius: '10px',
	overflow: 'hidden',
	border: '1px solid #acacac',
	boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)',
}

const TitleStyle: SxProps<Theme> = {
	bgcolor: '#f6f6f6',
	backgroundImage: 'linear-gradient(180deg, #ebebeb 0%, #d5d5d5 100%)',
	p: 0.75,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	borderBottom: '1px solid #acacac',
};

const ActionButtons: React.FunctionComponent = () => (
	<Stack direction={'row'} spacing={0.5} sx={{ ml: 1, position: 'absolute' }}>
		<IconButton size={'small'} sx={{ p: 0, '&:hover': { bgcolor: 'transparent' } }}>
			<FiberManualRecordIcon sx={{ fontSize: 12, color: '#ff5f56' }} />
		</IconButton>
		<IconButton size={'small'} sx={{ p: 0, '&:hover': { bgcolor: 'transparent' } }}>
			<FiberManualRecordIcon sx={{ fontSize: 12, color: '#ffbd2e' }} />
		</IconButton>
		<IconButton size={'small'} sx={{ p: 0, '&:hover': { bgcolor: 'transparent' } }}>
			<FiberManualRecordIcon sx={{ fontSize: 12, color: '#27c93f' }} />
		</IconButton>
	</Stack>
);

const Title: React.FunctionComponent<{ sx?: SxProps<Theme>, children: React.ReactNode }> = (props) => (
	<Typography variant={'body2'} sx={props.sx}>{props.children}</Typography>
);

export const Application: React.FunctionComponent<IProps> = (props) => (
	<Paper elevation={3} sx={ApplicationStyle}>
		<Box sx={TitleStyle}>
			<ActionButtons />
			<Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
				<Title sx={props.titleStyle || { fontWeight: 500, color: '#4d4d4d' }}>{props.title}</Title>
			</Box>
		</Box>
		<Box sx={props.sx || { p: 2, bgcolor: '#ffffff', color: '#4d4d4d', height: '100%', maxHeight: '567px' }}>{props.children}</Box>
	</Paper>
);

export default Application;
