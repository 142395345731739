import React from 'react';
import { Avatar, Card, CardContent, CardHeader, Grid, Modal as PopupModal, Stack } from '@mui/material';

interface IModalProps {
	icon: React.ReactNode;
	title: string;
	open: boolean;
	titleSx?: React.CSSProperties;
	onClose: () => void;
	children: React.ReactNode;
}

const CardStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '20px',
	maxWidth: '800px',

	'&.MuiPaper-elevation1': {
		boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
	}
};

const IconStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	boxShadow: 'var(--mui-shadows-8)',
	color: 'var(--mui-palette-text-primary)',
};

const CardTitleStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '1.125rem',
	lineHeight: 1.2,
	display: 'block',
};

const CardContentStyle = {
	padding: '16px 24px 32px',
	paddingBottom: '32px',

	'&:last-child': {
		paddingBottom: '32px',
	}
};

export const Modal: React.FunctionComponent<IModalProps> = (props) => (
	<PopupModal open={props.open} onClose={props.onClose}>
		<Grid container spacing={3} sx={{ height: '100vh', pointerEvents: 'none' }} justifyContent={'center'} alignItems={'center'}>
			<Grid item sx={{ pointerEvents: 'auto' }}>
				<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
					<Card sx={CardStyle} elevation={1}>
						<CardHeader sx={{ display: 'flex', alignItems: 'center', padding: '32px 24px 16px' }}
							avatar={<Avatar sx={IconStyle} variant={'circular'}>{props.icon}</Avatar>}
							titleTypographyProps={{ sx: { ...CardTitleStyle, ...props.titleSx } }} title={props.title} />
						<CardContent sx={CardContentStyle}>{props.children}</CardContent>
					</Card>
				</Stack>
			</Grid>
		</Grid>
	</PopupModal>
);

export default Modal;
