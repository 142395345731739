import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material';
import Theme from './theme';
import ColorScheme from './colors';

export const WithTheme: React.FunctionComponent<{children: React.ReactNode}> = (props) => (
	<CssVarsProvider theme={ColorScheme}>
		<ThemeProvider theme={Theme}>
			<CssBaseline />
			{props.children}
		</ThemeProvider>
	</CssVarsProvider>
);

export default WithTheme;
