import React from 'react';
import { observer } from 'mobx-react';
import { Badge, Box, Stack, Avatar, Popover, List, ButtonBase, ListItemIcon, Divider, MenuItem } from '@mui/material';
import { PersonOutline, LockOutlined, ShieldOutlined } from '@mui/icons-material';
import { AccountSettingsPath } from 'src/pages/settings/account';
import { LogoutPath } from 'src/pages/auth/logout';
import { SecuritySettingsPath } from 'src/pages/settings/security';
import { ManagersSettingsPath } from 'src/pages/settings/managers';
import store from 'src/store';

interface IPopoverProps {
	anchorEl: HTMLButtonElement | null;
	handleClose: () => void;
	children: React.ReactNode;
}

interface IPopoverButtonProps {
	to: string;
	title: string;
	icon: React.ReactNode;
}

const AppBarStyle = {
	backgroundColor: 'var(--mui-palette-background-default)',
	left: '0px',
	position: 'sticky',
	top: '0px',
	width: '100%',
	zIndex: '1000',
};

const HeaderStyle = {
	borderBottom: '1px solid var(--mui-palette-divider)',
	display: 'flex',
	flex: '1 1 auto',
	minHeight: '56px',
	paddingTop: '8px',
	paddingBottom: '8px',

	'@media(min-width: 0px)': {
		paddingLeft: '16px',
		paddingRight: '16px',
	},

	'@media(min-width: 1200px)': {
		paddingLeft: '24px',
		paddingRight: '24px',
	},
};

const AccountActionsStyle = {
	display: 'flex',
	flexDirection: 'row',
	gap: '16px',
	alignItems: 'center',
	flex: '1 1 auto',
	justifyContent: 'flex-end',
}

const AvatarButtonStyle = {
	border: 'medium',
	background: 'transparent',
	cursor: 'pointer',
	padding: '0px',
};

const AvatarBadgeStyle = {
	position: 'relative',
	display: 'inline-flex',
	verticalAlign: 'middle',
	flexShrink: '0',
};

const AvatarRootStyle = {
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexShrink: '0',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	lineHeight: '1',
	borderRadius: '50%',
	overflow: 'hidden',
	userSelect: 'none',
	fontSize: '0.875rem',
	fontWeight: '500',
	letterSpacing: '0px',
	height: '40px',
	width: '40px',
};

const AvatarImageStyle = {
	width: '100%',
	height: '100%',
	textAlign: 'center',
	objectFit: 'cover',
	color: 'transparent',
	textIndent: '10000px',
};

const AccountPopoverStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	borderRadius: '8px',
	boxShadow: 'var(--mui-shadows-16)',
	backgroundImage: 'none',
	position: 'absolute',
	overflow: 'hidden auto',
	minWidth: '16px',
	minHeight: '16px',
	maxWidth: 'calc(100% - 32px)',
	maxHeight: 'calc(100% - 32px)',
	outline: '0px',
	border: '1px solid var(--mui-palette-divider)',
	width: '280px',
};

const AccountPopoverButtonStyle = {
	backgroundColor: 'transparent',
	outline: '0px',
	border: '0px',
	margin: '0px',
	cursor: 'pointer',
	userSelect: 'none',
	verticalAlign: 'middle',
	appearance: 'none',
	color: 'inherit',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '1rem',
	lineHeight: 1.5,
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	position: 'relative',
	textDecoration: 'none',
	minHeight: '48px',
	padding: '6px 16px',
	boxSizing: 'border-box',
	whiteSpace: 'nowrap',
	borderRadius: '8px',
	gap: '16px',

	'&:hover': {
		textDecoration: 'none',
		backgroundColor: 'var(--mui-palette-action-hover)',
	},

	'@media (min-width: 600px)': {
		minHeight: 'auto',
	}
};

const PopoverDividerStyle = {
	margin: '0px',
	flexShrink: 0,
	borderWidth: '0px 0px thin',
	borderStyle: 'solid',
	borderColor: 'var(--mui-palette-divider)',
};

const LogoutButtonStyle = {
	backgroundColor: 'transparent',
	outline: '0px',
	border: '0px',
	margin: '0px',
	cursor: 'pointer',
	userSelect: 'none',
	verticalAlign: 'middle',
	appearance: 'none',
	color: 'inherit',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '1rem',
	lineHeight: 1.5,
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	textDecoration: 'none',
	minHeight: '48px',
	padding: '6px 16px',
	boxSizing: 'border-box',
	whiteSpace: 'nowrap',
	borderRadius: '8px',
	justifyContent: 'center',
};

const AccountPopover: React.FunctionComponent<IPopoverProps> = (props) => {
	return (
		<Popover
			anchorEl={props.anchorEl}
			slotProps={{
				paper: {
					elevation: 16,
					sx: AccountPopoverStyle,
					style: {
						opacity: 1,
						transform: 'none',
						transition: 'opacity 294ms cubic-bezier(0.4, 0, 0.2, 1), transform 196ms cubic-bezier(0.4, 0, 0.2, 1)',
						top: '48px',
						left: '1436px',
						transformOrigin: '280px 0px 0px',
					}
				}
			}}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={Boolean(props.anchorEl)}
			onClose={props.handleClose}>{props.children}</Popover>
	);
}

const AccountButton: React.FunctionComponent<IPopoverButtonProps> = (props) => (
	<ButtonBase sx={AccountPopoverButtonStyle} href={props.to}>
		<ListItemIcon sx={{ flexShrink: 0, display: 'inline-flex', color: 'inherit', fontSize: '1.25rem', minWidth: 'auto' }}>{props.icon}</ListItemIcon>
		{props.title}
	</ButtonBase>
);

export const AppBar: React.FunctionComponent = observer(() => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const account = store.auth.account;
	return (
		<Box component={'header'} sx={AppBarStyle}>
			<Box sx={HeaderStyle}>
				<Stack sx={AccountActionsStyle}>
					<Box component={'button'} onClick={handleClick} sx={AvatarButtonStyle}>
						<Badge component={'span'} sx={AvatarBadgeStyle}>
							<Avatar variant={'circular'} sx={AvatarRootStyle} slotProps={{ img: { sx: AvatarImageStyle } }} src={account?.avatarUrl}>{account?.displayName[0]}</Avatar>
						</Badge>
					</Box>
					<AccountPopover anchorEl={anchorEl} handleClose={handleClose}>
						<List sx={{ listStyle: 'none', margin: '0px', position: 'relative', padding: '8px' }}>
							<AccountButton to={AccountSettingsPath} title={'Account'} icon={<PersonOutline />} />
							<AccountButton to={SecuritySettingsPath} title={'Security'} icon={<LockOutlined />} />
							<AccountButton to={ManagersSettingsPath} title={'Account Managers'} icon={<ShieldOutlined />} />
						</List>
						<Divider sx={PopoverDividerStyle} />
						<Box sx={{ padding: '8px' }}>
							<MenuItem component={'div'} onClick={() => window.location.href = LogoutPath} sx={LogoutButtonStyle}>Logout</MenuItem>
						</Box>
					</AccountPopover>
				</Stack>
			</Box>
		</Box>
	);
});

export default AppBar;
