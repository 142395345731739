import React from 'react';
import { observer } from 'mobx-react';
import { Box, Stack } from '@mui/material';
import { InsightsOutlined, StreamOutlined, Paid, AdminPanelSettingsOutlined, Cast } from '@mui/icons-material';
// import { Layers, QueueMusic, Storage, Store } from '@mui/icons-material';
// import { KeyboardCommandKey, TimerOutlined, SpeakerNotesOff, ManageAccounts, Settings } from '@mui/icons-material';
import store from 'src/store';

import { OverviewPath } from 'src/pages/dashboard/overview';

import { LinkedLogo } from 'src/components/Logo';
import ChannelSelect from 'src/components/ChannelSelect';
import Navigation, { NavGroup, NavItem } from 'src/components/Navigation';

import { ActivitiesPath } from 'src/pages/dashboard/activity';
import { RevenuePath } from 'src/pages/dashboard/revenue';
import { AdminPath } from 'src/pages/dashboard/admin';
import { OBSPath } from 'src/pages/dashboard/obs';

const SidebarStyle = {
	borderRight: '1px solid var(--mui-palette-divider)',
	backgroundColor: 'var(--mui-palette-background-secondary)',
	color: 'var(--mui-palette-text-primary)',
	position: 'fixed',
	flexDirection: 'column',
	top: '0px',
	left: '0px',
	height: '100%',
	width: '280px',
	zIndex: '1100',

	'@media(min-width: 0px)': {
		display: 'none',
	},
	'@media(min-width: 1200px)': {
		display: 'flex',
	},
	
	'a': {
		textDecoration: 'none',
		color: '#ffffff',
	},
}

export const Sidebar: React.FunctionComponent = observer(() => {
	const isStaff = store.auth.isStaff;

	return (
		<Box sx={SidebarStyle}>
			<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}>
				<div style={{ display: 'inline-flex' }}><LinkedLogo sx={{ fontSize: '32px' }} to={'/'} /></div>
				<ChannelSelect />
			</Stack>
			<Navigation>
				<NavGroup title={'Dashboards'}>
					<NavItem to={OverviewPath} title={'Overview'} icon={<InsightsOutlined />} active />
					<NavItem to={ActivitiesPath} title={'Activities'} icon={<StreamOutlined />} />
					<NavItem to={RevenuePath} title={'Revenue'} icon={<Paid />} />
					{ isStaff? <NavItem to={AdminPath} title={'Admin'} icon={<AdminPanelSettingsOutlined />} /> : <></> }
				</NavGroup>
				<NavGroup title={'Overlays'}>
					{ /* <NavItem to={'/overlays'} title={'Manage'} icon={<Layers />} />
					<NavItem to={'/overlays/media-request'} title={'Media Request'} icon={<QueueMusic />} /> */ }
					<NavItem to={OBSPath} title={'OBS'} icon={<Cast />} />
					{ /* <NavItem to={'/overlays/file-storage'} title={'File Storage'} icon={<Storage />} />
					<NavItem to={'/overlays/marketplace'} title={'Marketplace'} icon={<Store />} /> */ }
				</NavGroup>
				{ /* <NavGroup title={'Chat'}>
					<NavItem to={'/chat/commands'} title={'Commands'} icon={<KeyboardCommandKey />} />
					<NavItem to={'/chat/timers'} title={'Timers'} icon={<TimerOutlined />} />
					<NavItem to={'/chat/ban-phrases'} title={'Ban Phrases'} icon={<SpeakerNotesOff />} />
					<NavItem to={'/chat/user-management'} title={'User Management'} icon={<ManageAccounts />} />
					<NavItem to={'/chat/settings'} title={'Settings'} icon={<Settings />} />
				</NavGroup> */ }
			</Navigation>
		</Box>
	);
});

export default Sidebar;
