const protocol = window.location.protocol;
const domain = window.location.hostname === 'localhost' ? 'localhost' : 'anyways.tv';

interface EnvironmentMapping {
	env: Record<string, Config>;
}

interface Config {
	cookieHost: string;
	api: {
		baseUrl: string;
	};
}

const config: EnvironmentMapping = {
	env: {
		development: {
			cookieHost: 'localhost',
			api: {
				baseUrl: `${protocol}//localhost:8080`
			}
		},
		production: {
			cookieHost: `.${domain}`,
			api: {
				baseUrl: `${protocol}//api.${domain}`
			}
		}
	}
};

const getConfig = () => {
	const environment = domain === 'localhost' ? 'development' : 'production';
	return config.env[environment];
};

export default getConfig();
