import React from 'react';
import DashboardPage from 'src/components/Dashboard/DashboardPage';
import Sidebar from 'src/components/Sidebar';
import DashboardWrapper from 'src/components/Dashboard/DashboardWrapper';
import AppBar from 'src/components/AppBar';
import DashboardContent from 'src/components/Dashboard/DashboardContent';

interface IProps {
	children?: React.ReactNode;
}

const Dashboard: React.FunctionComponent<IProps> = ({ children }) => (
	<DashboardPage>
		<Sidebar />
		<DashboardWrapper>
			<AppBar />
			{children}
		</DashboardWrapper>
	</DashboardPage>
);

export { DashboardContent };
export default Dashboard;
