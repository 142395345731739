import React from 'react';
import { Avatar, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

interface IProps {
	icon: React.ReactNode;
	title: string;
	titleSx?: React.CSSProperties;
	subheader?: string;
	subheaderSx?: React.CSSProperties;
	children: React.ReactNode;
	endAdornment?: React.ReactNode;
}

const SettingsCardStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '20px',

	'&.MuiPaper-elevation1': {
		boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
	}
};

const SettingsIconStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	boxShadow: 'var(--mui-shadows-8)',
	color: 'var(--mui-palette-text-primary)',
};

const SettingsCardTitleStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '1.125rem',
	lineHeight: 1.2,
	display: 'block',
};

const SettingsTitleStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '2rem',
	lineHeight: 1.2,
};

const SettingsSubheaderStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '0.875rem',
	lineHeight: 1.57,
	color: 'var(--mui-palette-text-secondary)',
	display: 'block',
};

export const SettingsTitle: React.FunctionComponent<{title: string}> = ({ title }) => (
	<div><Typography sx={SettingsTitleStyle} variant={'h4'}>{title}</Typography></div>
);

export const SettingsPanel: React.FunctionComponent<IProps> = (props) => (
	<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
		<Card sx={SettingsCardStyle} elevation={1}>
			<CardHeader sx={{ display: 'flex', alignItems: 'center', padding: '32px 24px 16px' }}
				avatar={<Avatar sx={SettingsIconStyle} variant={'circular'}>{props.icon}</Avatar>}
				titleTypographyProps={{ sx: { ...SettingsCardTitleStyle, ...props.titleSx } }} title={props.title}
				subheader={props.subheader} subheaderTypographyProps={{ sx: { ...SettingsSubheaderStyle, ...props.subheaderSx } }} />
			<CardContent sx={{
				padding: '16px 24px 32px',
				paddingBottom: '32px',

				'&:last-child': {
					paddingBottom: '32px',
				}
			}}>{props.children}</CardContent>
			{props.endAdornment}
		</Card>
	</Stack>
);

export default SettingsPanel;