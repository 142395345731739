import { observable, runInAction, makeAutoObservable } from 'mobx';
import Config from 'src/services/config';
import AnywaysAPI from 'src/services/anyways';

export interface IAccount {
	_id: string;
	username: string;
	displayName: string;
	avatarUrl?: string;
	alias: string;
	role?: string;
	moderators?: string[];
	flags?: Record<string, any>;
	suspended: boolean;
	createdAt: string;
	updatedAt: string;
	deactivatedAt?: string;
	password?: boolean;
}

export interface IContext {
	_id: string;
	username: string;
	displayName: string;
	avatarUrl?: string;
	alias: string;
	role: string;
	flags?: Record<string, any>;
	live: boolean;
	suspended: boolean;
	createdAt: string;
	updatedAt: string;
	deactivatedAt?: string;
}

export interface IAccountAccess {
	_id: string;
	username: string;
	displayName: string;
	avatarUrl?: string;
	alias: string;
	role: string;
	flags?: Record<string, any>;
	live: boolean;
	createdAt: string;
	updatedAt: string;
}

export interface IConnection {
	_id: string;
	user: string;
	provider: string;
	providerId: string;
	connected: boolean;
	createdAt: string;
	updatedAt: string;
}

const cookieRx: RegExp = /sessionid=([^;]+)/i;

export default class AuthStore {
	@observable public isAuthenticated: boolean;
	@observable public account: IAccount | null;
	@observable public context: IContext | null;
	@observable public access: IAccountAccess[] = [];
	@observable public connections: IConnection[] = [];
	@observable public token: string | null;
	@observable public isReady: boolean = false;

	public constructor() {
		this.isAuthenticated = false;
		this.account = null;
		this.context = null;
		this.token = this.findCookie();

		this.login();
		makeAutoObservable(this);
	}

	private login() {
		if(!this.token) {
			this.isAuthenticated = false;
			this.isReady = true;
			return;
		}

		AnywaysAPI.setToken(this.token);
		this.isAuthenticated = true;
		Promise.allSettled([
			AnywaysAPI.GetAccount(),
			AnywaysAPI.GetContext(),
			AnywaysAPI.GetAccess(),
			this.RefreshIntegrations(),
		]).then(([account, context, access, _]) => {
			runInAction(() => {
				this.account = account.status === 'fulfilled' ? this.account = account.value.data : null;
				this.context = context.status === 'fulfilled' ? this.context = context.value.data : null;
				this.access = access.status === 'fulfilled' ? this.access = access.value.data : null;

				if(!this.account || !this.context) this.Logout();
				this.isReady = true;
			});
		});
	}

	public get isStaff() {
		return this.account?.role === 'staff';
	}

	public async SetContextID(id: string) {
		await AnywaysAPI.SetContextID(id);
		const context = await AnywaysAPI.GetContext();
		runInAction(() => {
			this.context = context.data;
		});
	}

	public RefreshIntegrations(): Promise<void> {
		return runInAction(async () => {
			const connections = await AnywaysAPI.ListIntegrations();
			this.connections = connections.data || [];
		});
	}

	public Logout() {
		runInAction(() => {
			this.token = null;
			if(this.isAuthenticated) {
				this.isAuthenticated = false;
				this.account = null;
				this.context = null;
				this.connections = [];
			}
			document.cookie = `sessionid=; Domain=${Config.cookieHost}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=Lax`;
		});
	}

	private findCookie(): string | null {
		if(this.token) return this.token;
		const search: string[] | null = cookieRx.exec(document.cookie);
		if(search && search[1]) return search[1];
		return null;
	}
}
