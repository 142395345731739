import React from 'react';
import Dashboard, { DashboardContent } from 'src/components/Dashboard';
import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const RevenuePath = '/dashboard/revenue';
export const Revenue: React.FunctionComponent = () => (
	<Dashboard>
		<DashboardContent title={'Revenue'}>
			<Grid2 container direction={{ xs: 'row' }} spacing={{ xs: 4 }}>
				<Grid2 xs={12} md={6}>
					<Box>
						<Typography variant={'h6'}>Total Revenue</Typography>
					</Box>
				</Grid2>
			</Grid2>
		</DashboardContent>
	</Dashboard>
);

export default Revenue;
