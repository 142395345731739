import React, { useState, useEffect } from 'react';
import { Box, Stack, Alert, TextField, Button, Paper, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { Password, History } from '@mui/icons-material';
import Dashboard from 'src/components/Dashboard';
import SettingsPanel, { SettingsTitle } from 'src/components/SettingsPanel';
import Turnstile from 'src/components/Turnstile';
import AnywaysAPI, { LoginEntry } from 'src/services/anyways';
import store from 'src/store';
import { SettingsNavigation } from '.';

const PageWrapperStyle = {
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'column',

	'--Content-margin': '0 auto',
	'--Content-maxWidth': '1440px',
	'--Content-paddingX': '24px',
	'--Content-padding': 'var(--Content-paddingY) var(--Content-paddingX)',
	'--Content-width': '100%',

	'@media (min-width: 0px)': {
		'--Content-paddingY': '24px',
	},
	'@media (min-width: 1200px)': {
		'--Content-paddingY': '64px',
	},
};

const PageContentStyle = {
	maxWidth: 'var(--Content-maxWidth)',
	margin: 'var(--Content-margin)',
	padding: 'var(--Content-padding)',
	width: 'var(--Content-width)',
};

const SettingsWrapperStyle = {
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',

	'@media (min-width: 0px)': {
		flexDirection: 'column',
		gap: '32px',
	},

	'@media (min-width: 900px)': {
		flexDirection: 'row',
		gap: '32px',
	},
};

const StackStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '16px',
}

const LoginHistoryWrapperStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	border: '1px solid var(--mui-palette-divider)',
	backgroundImage: 'none',
	overflow: 'auto hidden',
	borderRadius: '20px',
}

const LoginHistoryTableStyle = {
	display: 'table',
	width: '100%',
	borderCollapse: 'collapse',
	borderSpacing: 0,
}

const LoginHistoryTableHeaderStyle = {
	display: 'table-header-group',

	'& .MuiTableCell-root': {
		backgroundColor: 'var(--mui-palette-neutral-800)',
		color: 'var(--mui-palette-text-secondary)',
		lineHeight: 1,
	}
}

const LoginHistoryTableHeaderRowStyle = {
	color: 'inherit',
	display: 'table-row',
	verticalAlign: 'middle',
	outline: 0,
}

const LoginHistoryIPStyle = {
	'&:hover:not(.showing)': {
		cursor: 'pointer',
		textDecoration: 'underline',
	},
}

const LoginHistoryRow: React.FunctionComponent<{ login: LoginEntry }> = ({ login }) => {
	const ShowIP = (e: React.MouseEvent<HTMLSpanElement>) => {
		const target = e.target as HTMLSpanElement;
		target.textContent = login.ipAddress;
		target.classList.add('showing');
	}

	return (
		<TableRow>
			<TableCell variant={'body'} size={'medium'}>{login.createdAt.toString()}</TableCell>
			<TableCell variant={'body'} size={'medium'}>
				<Box component={'span'} sx={LoginHistoryIPStyle} onClick={ShowIP}>{login.ipAddress.replace(/./g, '*')}</Box>
			</TableCell>
			<TableCell variant={'body'} size={'medium'}>{login.userAgent}</TableCell>
		</TableRow>
	);
}

const ChangePasswordPanel: React.FunctionComponent = () => {
	const [alert, setAlert] = useState<{ type: 'error' | 'warning' | 'success'; message: string }>();
	if(store.isReady && !alert && !store.auth.account?.password) {
		setAlert({ type: 'warning', message: 'You have not set a password. Logging in via email is disabled without one.' });
	}

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			const form = e.target as HTMLFormElement;
			const formData = new FormData(form);
			const res = await fetch(`${AnywaysAPI.BaseURL()}/auth/password`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': `Bearer ${store.auth.token}`,
				},
				body: [...formData.entries()].map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1] as any)).join("&"),
			});

			if(res.status < 400) {
				setAlert({ type: 'success', message: 'Password updated successfully.' });
				form.reset();
				return;
			}
			setAlert({ type: 'error', message: await res.text() });
		} catch(error) {
			setAlert({ type: 'error', message: 'Something went wrong.' });
		}
	}

	return (
		<SettingsPanel icon={<Password />} title={'Change Password'}>
			{ alert && <Alert sx={{ marginBottom: '32px' }} severity={alert.type}>{alert.message}</Alert> }
			<Stack sx={StackStyle} component={'form'} onSubmit={onSubmit}>
				{ store.auth.account?.password ? <TextField label={'Current Password'} name={'current-password'} type={'password'} required /> : <></> }
				<Stack sx={{ ...StackStyle, flexDirection: 'row' }}>
					<TextField sx={{ flexGrow: 1 }} label={'New Password'} name={'password'} type={'password'} required />
					<TextField sx={{ flexGrow: 1 }} label={'Confirm New Password'} name={'confirm-password'} type={'password'} required />
				</Stack>
				<Box style={{ marginLeft: 'auto', marginRight: 0 }}>
					<Turnstile action={'update-password'} onValidateFailure={(msg) => msg ? setAlert({ type: 'error', message: msg }) : 0} />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'end' }}>
					<Button variant={'contained'} type={'submit'}>Update Password</Button>
				</Box>
			</Stack>
		</SettingsPanel>
	);
}

const LoginHistoryPanel: React.FunctionComponent = () => {
	const [logins, setLogins] = useState<LoginEntry[]>([]);
	useEffect(() => {
		AnywaysAPI.LoginHistory().then(res => {
			setLogins(res.slice(0, 5));
		});
	}, []);

	return (
		<SettingsPanel icon={<History />} title={'Login History'}>
			<Paper sx={LoginHistoryWrapperStyle}>
				<Table sx={LoginHistoryTableStyle}>
					<TableHead sx={LoginHistoryTableHeaderStyle}>
						<TableRow sx={LoginHistoryTableHeaderRowStyle}>
							<TableCell sx={{}} variant={'head'} size={'medium'}>Login Type</TableCell>
							<TableCell sx={{}} variant={'head'} size={'medium'}>IP Address</TableCell>
							<TableCell sx={{}} variant={'head'} size={'medium'}>User Agent</TableCell>
						</TableRow>
					</TableHead>
					<TableBody sx={{ display: 'table-row-group' }}>
						{logins.map((login, index) => <LoginHistoryRow key={index} login={login} />)}
					</TableBody>
				</Table>
			</Paper>
		</SettingsPanel>
	);
}

export const SecuritySettingsPath = '/dashboard/settings/security';
export const SecuritySettings: React.FunctionComponent = () => (
	<Dashboard>
		<Box sx={PageWrapperStyle} component={'main'}>
			<Box sx={PageContentStyle}>
				<Stack sx={SettingsWrapperStyle}>
					<SettingsNavigation />
					<Box sx={{ flex: '1 1 auto', minWidth: '0px' }}>
						<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
							<SettingsTitle title={'Security'} />
							<ChangePasswordPanel />
							<LoginHistoryPanel />
						</Stack>
					</Box>
				</Stack>
			</Box>
		</Box>
	</Dashboard>
);

export default SecuritySettings;
