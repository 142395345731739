import React from 'react';
import { Box } from '@mui/material';

interface IProps {
	children?: React.ReactNode;
}

const ContentStyle = {
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'column',
	
	'@media(min-width: 1200px)': {
		paddingLeft: '280px'
	}
}

export const DashboardWrapper: React.FunctionComponent<IProps> = (props) => (
	<Box sx={ContentStyle}>{props.children}</Box>
);

export default DashboardWrapper;
