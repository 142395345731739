import API from './api';
import Config from './config';

export interface LoginEntry {
	ipAddress: string;
	userAgent: string;
	createdAt: Date;
}

class AnywaysService extends API {
	constructor() {
		super({
			baseUrl: Config.api.baseUrl,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	// Turnstile token verification
	public Verify(token: string) {
		return this.post('/turnstile/verify', { token });
	}

	// Get account information for the authorized account
	public GetAccount() {
		return this.get('/v1/account');
	}

	// Get account information for the authorized account
	public GetContext() {
		return this.get('/v1/account/context');
	}

	public GetAccess() {
		return this.get('/v1/account/access');
	}

	// Get account managers for the authorized account
	public Managers() {
		return this.get('/v1/account/managers');
	}

	// List all integrations for the authorized account
	public ListIntegrations() {
		return this.get('/v1/account/integrations');
	}

	public SetContextID(contextId: string) {
		return this.patch('/v1/account/context', { context: contextId });
	}

	// Remove integration from the authorized account by ID
	public RemoveIntegration(integrationId: string) {
		return this.delete(`/auth/provider/disconnect/${integrationId}`);
	}

	// Get login history for the authorized account
	public async LoginHistory(): Promise<LoginEntry[]> {
		const res = await this.get('/v1/account/logins');
		return res.data;
	}

	// Create new account
	public CreateAccount(data: object) {
		return this.post('/auth/register', data);
	}

	// Update password for the authorized account.
	public UpdatePassword(body: object) {
		return this.put('/auth/password', body);
	}

	// Deactivate the authorized account
	public DeactivateAccount() {
		return this.put('/auth/deactivate');
	}

	// Set the authorization token for the API
	public setToken(token: string) {
		this.headers['Authorization'] = `Bearer ${token}`;
	}
}

const AnywaysAPI = new AnywaysService();
export default AnywaysAPI;
