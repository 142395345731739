import { observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import NotFound from 'src/pages/404';
import { LoginPath } from 'src/pages/auth/login';
import { OverviewPath } from 'src/pages/dashboard/overview';
import { LoadingPage } from '../AsyncLoader';
import store from 'src/store';

export type AuthMode = 'optional' | 'noAuth' | 'required' | 'staff';

interface IProps {
	mode: AuthMode;
	children: React.ReactNode;
}

const CheckAuth: React.FunctionComponent<IProps> = observer((props) => {
	if(!store.isReady) return <LoadingPage />;

	if(props.mode !== 'optional') {
		const isLoggedIn = store.IsLoggedIn;
		if(!isLoggedIn && props.mode === 'required') return <Navigate to={LoginPath} />;
		if(isLoggedIn && props.mode === 'noAuth') return <Navigate to={OverviewPath} />;
		if(isLoggedIn && props.mode === 'staff' && !store.auth.isStaff) return <NotFound />;
	}
	return <>{props.children}</>;
});

export default CheckAuth;
