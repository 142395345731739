import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'mobx-react';
import reportWebVitals from './reportWebVitals';
import WithTheme from './styles';
import PageRouter from './pages';
import RootStore from './store';

const App: React.FunctionComponent = () => (
	<WithTheme>
		<Provider store={RootStore}>
			<PageRouter />
		</Provider>
	</WithTheme>
);

const root = ReactDOM.createRoot(document.getElementsByTagName('body')[0] as HTMLElement);
root.render(<React.StrictMode><App /></React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
