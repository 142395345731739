import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ShieldOutlined } from '@mui/icons-material';
import Dashboard from 'src/components/Dashboard';
import SettingsPanel, { SettingsTitle } from 'src/components/SettingsPanel';
import { SettingsNavigation } from '.';

const PageWrapperStyle = {
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'column',

	'--Content-margin': '0 auto',
	'--Content-maxWidth': '1440px',
	'--Content-paddingX': '24px',
	'--Content-padding': 'var(--Content-paddingY) var(--Content-paddingX)',
	'--Content-width': '100%',

	'@media(min-width: 0px)': {
		'--Content-paddingY': '24px',
	},
	'@media(min-width: 1200px)': {
		'--Content-paddingY': '64px',
	},
};

const PageContentStyle = {
	maxWidth: 'var(--Content-maxWidth)',
	margin: 'var(--Content-margin)',
	padding: 'var(--Content-padding)',
	width: 'var(--Content-width)',
};

const SettingsWrapperStyle = {
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',

	'@media(min-width: 0px)': {
		flexDirection: 'column',
		gap: '32px',
	},

	'@media(min-width: 900px)': {
		flexDirection: 'row',
		gap: '32px',
	},
};

export const ManagersSettingsPath = '/dashboard/settings/managers';
export const ManagersSettings: React.FunctionComponent = () => (
	<Dashboard>
		<Box sx={PageWrapperStyle} component={'main'}>
			<Box sx={PageContentStyle}>
				<Stack sx={SettingsWrapperStyle}>
					<SettingsNavigation />
					<Box sx={{ flex: '1 1 auto', minWidth: '0px' }}>
						<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
							<SettingsTitle title={'Account Managers'} />
							<SettingsPanel icon={<ShieldOutlined />} title={'Invite Managers'}>
								<Typography>Account Managers are users that have access to your account.</Typography>
							</SettingsPanel>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</Box>
	</Dashboard>
);

export default ManagersSettings;
