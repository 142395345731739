import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Avatar, Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { LinkOutlined } from '@mui/icons-material';
import AnywaysAPI from 'src/services/anyways';
import Dashboard from 'src/components/Dashboard';
import SettingsPanel, { SettingsTitle } from 'src/components/SettingsPanel';
import { SettingsNavigation } from '.';
import store from 'src/store';

interface IProviderProps {
	title: string;
	provider?: string;
	description: string;
	url?: string;
	logoUrl: string;
}

const PageWrapperStyle = {
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'column',

	'--Content-margin': '0 auto',
	'--Content-maxWidth': '1440px',
	'--Content-paddingX': '24px',
	'--Content-padding': 'var(--Content-paddingY) var(--Content-paddingX)',
	'--Content-width': '100%',

	'@media (min-width: 0px)': {
		'--Content-paddingY': '24px',
	},
	'@media (min-width: 1200px)': {
		'--Content-paddingY': '64px',
	},
};

const PageContentStyle = {
	maxWidth: 'var(--Content-maxWidth)',
	margin: 'var(--Content-margin)',
	padding: 'var(--Content-padding)',
	width: 'var(--Content-width)',
};

const SettingsWrapperStyle = {
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',

	'@media(min-width: 0px)': {
		flexDirection: 'column',
		gap: '32px',
	},

	'@media(min-width: 900px)': {
		flexDirection: 'row',
		gap: '32px',
	},
};

const IntegrationCardStyle = {
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	border: '1px solid var(--mui-palette-divider)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '8px',
};

const IntegrationDivider = {
	margin: '0px',
	flexShrink: 0,
	borderWidth: '0px 0px thin',
	borderStyle: 'solid',
	borderColor: 'var(--mui-palette-divider)',
};

const IntegrationProviderStyle = {
	display: 'flex',
	flexDirection: 'row',
	gap: '16px',
	alignItems: 'center',
	padding: '8px 16px',
};

const IntegrationProviderLogoStyle = {
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexShrink: 0,
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	lineHeight: 1,
	borderRadius: '50%',
	overflow: 'hidden',
	userSelect: 'none',
	fontSize: '0.875rem',
	fontWeight: 500,
	height: '40px',
	width: '40px',
	letterSpacing: '0px',
	backgroundColor: 'var(--mui-palette-background-paper)',
	boxShadow: 'var(--mui-shadows-8)',
	color: 'var(--mui-palette-text-primary)',
};

const IntegrationProviderNameStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '0.875rem',
	lineHeight: 1.57,
};

const IntegrationProviderDescriptionStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '0.75rem',
	lineHeight: 1.66,
	color: 'var(--mui-palette-text-secondary)',
};

const IntegrationProviderConnectStyle = {
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
	boxSizing: 'border-box',
	backgroundColor: 'transparent',
	outline: '0px',
	margin: '0px',
	cursor: 'pointer',
	userSelect: 'none',
	verticalAlign: 'middle',
	appearance: 'none',
	textDecoration: 'none',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	padding: '3px 9px',
	transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
	color: 'var(--mui-palette-secondary-main)',
	borderRadius: '8px',
	minHeight: '32px',
	minWidth: 'unset',
	textTransform: 'none',
	boxShadow: 'var(--mui-shadows-1)',
	borderColor: 'var(--mui-palette-secondary-700)',
	fontSize: '0.8125rem',
	lineHeight: '24px',

	'&[data-connected=true]': {
		color: 'var(--mui-palette-error-main)',
	},

	'&[data-connected=true]:hover': {
		color: 'var(--mui-palette-error-main)',
		borderColor: 'var(--mui-palette-error-main)',
		backgroundColor: 'rgba(var(--mui-palette-error-mainChannel) / var(--mui-palette-action-hoverOpacity))',
	},

	'&:hover:not([data-connected=true])': {
		borderColor: 'var(--mui-palette-secondary-700)',
		backgroundColor: 'var(--mui-palette-action-hover)',
	},
};

const IntegrationProvider: React.FunctionComponent<IProviderProps> = observer((props) => {
	const [ loading, setLoading ] = useState(false);
	const connection = store.auth.connections.find((connection) => connection.provider === (props.provider || props.title.toLowerCase()));
	const doRemoveConnection = (id: string) => {
		setLoading(true);
		AnywaysAPI.RemoveIntegration(id).then(() => {
			store.auth.RefreshIntegrations()
				.then(() => setLoading(false))
				.catch(() => setLoading(false));
		}).catch(() => setLoading(false));
	};

	return (
		<Stack sx={IntegrationProviderStyle}>
			<Avatar sx={IntegrationProviderLogoStyle} slotProps={{ img: { sx: { filter: props.url ? 'none' : 'grayscale(1)' } } }} alt={props.provider} src={props.logoUrl} />
			<Box sx={{ flex: '1 1 auto' }}>
				<Typography sx={IntegrationProviderNameStyle} variant={'subtitle2'}>{props.title}</Typography>
				<Typography sx={IntegrationProviderDescriptionStyle} variant={'caption'}>{props.description}</Typography>
			</Box>
			{connection ?
				<Button sx={IntegrationProviderConnectStyle} variant={'outlined'} size={'small'} data-connected={true} disabled={loading} onClick={loading ? undefined : () => doRemoveConnection(connection._id)}>Remove</Button> :
				props.url ? <Button sx={IntegrationProviderConnectStyle} variant={'outlined'} size={'small'} href={props.url}>Connect</Button> : <></>
			}
		</Stack>
	);
});

export const IntegrationsSettingsPath = '/dashboard/settings/integrations';
export const IntegrationsSettings: React.FunctionComponent = () => (
	<Dashboard>
		<Box sx={PageWrapperStyle} component={'main'}>
			<Box sx={PageContentStyle}>
				<Stack sx={SettingsWrapperStyle}>
					<SettingsNavigation />
					<Box sx={{ flex: '1 1 auto', minWidth: '0px' }}>
						<Stack sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
							<SettingsTitle title={'Integrations'} />
							<SettingsPanel icon={<LinkOutlined />} title={'Connected Accounts'}>
								<Card sx={IntegrationCardStyle} variant={'outlined'}>
									<Stack sx={{ display: 'flex', flexDirection: 'column' }}>
										<IntegrationProvider title={'Twitch'} description={'An interactive livestreaming service for content creators.'} url={`${AnywaysAPI.BaseURL()}/auth/provider/twitch`} logoUrl={'/assets/twitch.png'} />
										<Divider sx={IntegrationDivider} />
										<IntegrationProvider title={'Discord'} description={'A voice and text chat social media platform.'} url={`${AnywaysAPI.BaseURL()}/auth/provider/discord`} logoUrl={'/assets/discord.png'} />
									</Stack>
								</Card>
							</SettingsPanel>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</Box>
	</Dashboard>
);

export default IntegrationsSettings;
