import React from 'react';
import Dashboard, { DashboardContent } from 'src/components/Dashboard';

export const ActivitiesPath = '/dashboard/activity';
export const Activities: React.FunctionComponent = () => (
	<Dashboard>
		<DashboardContent title={'Activity'} />
	</Dashboard>
);

export default Activities;
