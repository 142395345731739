import React from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { AccountCircleOutlined, LinkOutlined, LockOutlined, ShieldOutlined } from '@mui/icons-material';
import { AccountSettingsPath } from './account';
import { SecuritySettingsPath } from './security';
import { ManagersSettingsPath } from './managers';
import { IntegrationsSettingsPath } from './integrations';
import store from 'src/store';

const NatigationWrapperStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '24px',
	flex: '0 0 auto',
	top: '64px',

	'@media (min-width: 0px)': {
		flexDirection: 'column-reverse',
		width: '100%',
	},
	'@media (min-width: 900px)': {
		flexDirection: 'column',
		position: 'sticky',
		width: '240px',
	},
};

const NavigationGroupStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '24px',
	listStyle: 'none',
	margin: '0px',
	padding: '0px',

	'--Content-margin': '0 auto',
	'--Content-maxWidth': '1440px',
	'--Content-paddingX': '24px',
	'--Content-padding': 'var(--Content-paddingY) var(--Content-paddingX)',
	'--Content-width': '100%',

	'@media (min-width: 0px)': {
		'--Content-paddingY': '24px',
	},
	'@media (min-width: 1200px)': {
		'--Content-paddingY': '64px',
	},
};

const NavigationGroupItemStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '16px',
};

const NavigationGroupCaptionStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '0.75rem',
	lineHeight: 1.66,
	color: 'var(--mui-palette-text-secondary)',
};

const NavigationGroupItemListStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
	listStyle: 'none',
	margin: '0px',
	padding: '0px',
};

const NavigationItemStyle = {
	alignItems: 'center',
	borderRadius: '8px',
	color: 'var(--mui-palette-text-secondary)',
	cursor: 'pointer',
	display: 'flex',
	flex: '0 0 auto',
	gap: '8px',
	padding: '6px 16px',
	textDecoration: 'none',
	whiteSpace: 'nowrap',

	'&:hover': {
		backgroundColor: 'var(--mui-palette-action-hover)',
		color: 'var(---mui-palette-text-primary)',
	},

	'&[data-active="true"]:not(:hover)': {
		backgroundColor: 'var(--mui-palette-action-selected)',
		color: 'var(--mui-palette-text-primary)',
	},
};

const NavigationIconStyle = {
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
	flex: '0 0 auto',
};

const SettingsNavigationGroup: React.FunctionComponent<{ title: string, children: React.ReactNode }> = (props) => (
	<Stack sx={NavigationGroupStyle} component={'ul'}>
		<Stack sx={NavigationGroupItemStyle} component={'li'}>
			<div><Typography sx={NavigationGroupCaptionStyle} variant={'caption'}>{props.title}</Typography></div>
			<Stack sx={NavigationGroupItemListStyle} component={'ul'}>{props.children}</Stack>
		</Stack>
	</Stack>
)

const SettingsNavigationItem: React.FunctionComponent<{ icon: React.ReactNode, title: string, to: string }> = (props) => (
	<Box sx={{ userSelect: 'none' }} component={'li'}>
		<Link sx={NavigationItemStyle} data-active={window.location.pathname === props.to ? true : undefined} href={props.to}>
			<Box sx={NavigationIconStyle}>{props.icon}</Box>
			<Box sx={{ flex: '1 1 auto' }}>
				<Typography>{props.title}</Typography>
			</Box>
		</Link>
	</Box>
)

export const SettingsNavigation: React.FunctionComponent = observer(() => {
	const setClipboard = (value: string) => navigator.clipboard.writeText(value)
	return (
		<div>
			<Stack sx={NatigationWrapperStyle}>
				<SettingsNavigationGroup title={'Personal'}>
					<SettingsNavigationItem icon={<AccountCircleOutlined />} title={'Account'} to={AccountSettingsPath} />
					<SettingsNavigationItem icon={<LockOutlined />} title={'Security'} to={SecuritySettingsPath} />
					<SettingsNavigationItem icon={<ShieldOutlined />} title={'Account Managers'} to={ManagersSettingsPath} />
					<SettingsNavigationItem icon={<LinkOutlined />} title={'Integrations'} to={IntegrationsSettingsPath} />
					
					<Button sx={{ marginTop: '15px' }} variant={'outlined'} color={'primary'} onClick={() => setClipboard(store.auth.account?._id || '')}>Copy Account ID</Button>
				</SettingsNavigationGroup>
			</Stack>
		</div>
	)
});
